<template>
  <ion-page>
    <ion-header>
      <ion-grid fixed style="padding: 0">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button default-href="/tools"></ion-back-button>
          </ion-buttons>
          <ion-title>
            {{ isRecording ? t('recording') : t('sounds') }}
          </ion-title>

          <ion-buttons slot="end">
            <ion-button v-show="isRecording">
              <ion-icon slot="icon-only" :icon="mic"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-grid>
    </ion-header>

    <ion-content>

      <ion-grid fixed>

        <ion-list class="ion-padding-horizontal">

          <ion-item class="ion-margin-vertical round-border-item" lines="none" v-for="sound in recordedSounds" :key="sound.id"
                    @click="toggleMusic(sound)" button>
            <ion-icon slot="start" :icon="play" v-show="!sound.isPlaying"></ion-icon>
            <ion-icon slot="start" :icon="pause" v-show="sound.isPlaying"></ion-icon>
            <ion-label>
              <h1>{{ sound.name }}</h1>
              <p>{{ formatDuration(sound.duration) }}</p>
              <p>{{ sound.createdAt }}</p>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button slot="icon-only" @click.stop="editSoundName(sound)">
                <ion-icon :icon="createOutline"></ion-icon>
              </ion-button>
              <ion-button slot="icon-only" @click.stop="deleteSound(sound)">
                <ion-icon :icon="trashOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>

        </ion-list>

      </ion-grid>

      <ion-fab vertical="bottom" horizontal="center" slot="fixed">
        <ion-fab-button :color="isRecording ? 'danger' : 'primary'" @click="toggleRecording">
          <ion-icon :icon="add" v-show="!isRecording"></ion-icon>
          <ion-icon :icon="stop" v-show="isRecording"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { add, mic, stop, play, pause, createOutline, trashOutline } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid,
        IonBackButton, IonButtons, IonButton, IonIcon, IonFab, IonFabButton,
        IonList, IonItem, IonLabel,
        alertController, loadingController,
        onIonViewWillLeave, onIonViewDidEnter, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { utils } from '@/composables/utils';

// plugins
import { Howl, Howler } from 'howler';
import { Media } from '@awesome-cordova-plugins/media';
import { Preferences } from '@capacitor/preferences';

export default {
  name: 'SoundListPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid,
                IonBackButton, IonButtons, IonButton, IonIcon, IonFab, IonFabButton,
                IonList, IonItem, IonLabel,
                LoadingSkeleton, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { sleep, presentPrompt, formatDateString, uniqueId, formatDuration, getMediaFileName } = utils();
    const SOUND_STORAGE_KEY = 'sounds';

    const isRecording = ref(false);
    const recordedSounds = ref([]);

    // 1. declare state variables (ref to make them reactive)
    let startRecordingTime = null;
    //let tmpMediaObj = null;
    //let recordingStartPosition, recordingEndPosition;
    let recordingMediaObj = null, stopSoundTimeout = null;
    const currSoundObj = { id: "", name: "", filePath: "", duration: "", createdAt: "", updatedAt: "" };

    /**
     * Play Recorded Sounds
     */

    const toggleMusic = (sound) => {
      if (sound.isPlaying) {
        sound.mediaObj.stop();
        clearTimeout(stopSoundTimeout);
      }
      else {
        const media = Media.create(sound.filePath);
        media.play();
        sound.mediaObj = media;
        stopSoundTimeout = setTimeout(() => {
          sound.isPlaying = false;
        }, sound.duration * 1000);
      }
      sound.isPlaying = !sound.isPlaying;
    }

    /**
     * Sound Storage
     */
    const syncSoundsToDB = async () => {
      return await Preferences.set({
        key: SOUND_STORAGE_KEY,
        value: JSON.stringify(recordedSounds.value)
      });
    }
    const saveSound = async (soundObj, alert) => {
      const loading = await loadingController.create({});
      await loading.present();

      soundObj.id = uniqueId();
      soundObj.createdAt = formatDateString(new Date(), "YYYY-MM-DD HH:mm:ss");
      soundObj.updatedAt = formatDateString(new Date(), "YYYY-MM-DD HH:mm:ss");
      recordedSounds.value.push({ ...soundObj });

      await syncSoundsToDB();

      loading.dismiss();

      if (alert) alert.dismiss();
    }
    const editSoundName = async (soundObj) => {
      const alert = await alertController.create({
        subHeader: `${t('enterSoundName')}`,
        inputs: [
          {
            name: 'soundName',
            type: 'text',
            value: soundObj.name,
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            cssClass: 'secondary',
            role: 'cancel',
          },
          {
            text: t('save'),
            handler: (value) => {
              if (value.soundName) {
                soundObj.name = value.soundName;
                syncSoundsToDB();
                alert.dismiss();
              }
              return false; // not closing the alert
            },
          },
        ],
      });
      await alert.present();
    }
    const deleteSound = async (sound) => {
      presentPrompt("", t('confirmDelete'), () => {
        const soundIdx = recordedSounds.value.findIndex(s => s.id == sound.id);
        if (soundIdx !== -1) recordedSounds.value.splice(soundIdx, 1); // panel shape (first element is on the top of layer)
        syncSoundsToDB();
      });
    }

    /**
     * Sound Recording
     */
    const checkRecordSound = async () => {
      if (isRecording.value == true) {
        currSoundObj.filePath = getMediaFileName(new Date().valueOf());
        recordingMediaObj = Media.create(currSoundObj.filePath);
        recordingMediaObj.startRecord();
        /*
        if (tmpMediaObj == null) {
          tmpMediaObj = Media.create(getMediaFileName('echo'));
          tmpMediaObj.startRecord();
        }
        // get media amplitude
        const amp = await tmpMediaObj.getCurrentAmplitude();
        console.log(amp);
        if (amp > 0.1) {
          //recordingStartPosition = tmpMediaObj.
          const recordingMediaObj = Media.create(getMediaFileName(new Date().valueOf()));
          recordingMediaObj.startRecord();
          setTimeout(() => {
            tmpMediaObj.stopRecord();
            tmpMediaObj.play();
            setTimeout(() => {
              tmpMediaObj.stop();
              tmpMediaObj.release();
              checkEchoSound();
            }, 3000);
          }, 3000)
        } else {
          //setTimeout(startRecording, 300); // check every 0.5
        }
        */
      }
    }
    const stopRecording = async () => {
      if (recordingMediaObj) {
        recordingMediaObj.stopRecord();
        currSoundObj.duration = Math.round((new Date() - startRecordingTime) / 1000);
        recordingMediaObj.release();
        recordingMediaObj = null;

        const alert = await alertController.create({
          backdropDismiss: false,
          header: t('saveSound'),
          subHeader: `${t('enterSoundName')}`,
          inputs: [
            {
              name: 'soundName',
              type: 'text',
              value: 'Untitled',
            },
          ],
          buttons: [
            {
              text: t('discard'),
              cssClass: 'secondary',
              handler: () => {
                // TODO: delete the sound file
              },
            },
            {
              text: t('save'),
              handler: (value) => {
                if (value.soundName) {
                  currSoundObj.name = value.soundName;
                  saveSound(currSoundObj, alert);
                }
                return false; // not closing the alert
              },
            },
          ],
        });
        await alert.present();
      }
      isRecording.value = false;
    }
    const startRecording = async () => {
      currSoundObj.filePath = getMediaFileName(new Date().valueOf());
      recordingMediaObj = Media.create(currSoundObj.filePath);
      recordingMediaObj.startRecord();
      startRecordingTime = new Date();
      isRecording.value = true;
    }
    const toggleRecording = () => {
      if (isRecording.value == true) {
        stopRecording();
      }
      else {
        presentPrompt("", t('startRecording'), () => {
          startRecording();
        });
      }
    }

    /**
     * On enter / leave view
     */
    onIonViewDidEnter(() => {
      Preferences.get({ key: SOUND_STORAGE_KEY }).then(ret => {
        recordedSounds.value = JSON.parse(ret.value || '[]');
      })
    })
    onIonViewWillLeave(() => { // clean-up
      stopRecording();
    });

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, mic, stop, play, pause, createOutline, trashOutline,

      // variables
      recordedSounds, isRecording,

      // methods
      t,
      toggleMusic,
      toggleRecording, startRecording, stopRecording,
      formatDuration, editSoundName, deleteSound,
    }
  }
}
</script>

<style scoped>
</style>